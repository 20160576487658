import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import LeaderboardTable from '../../components/Leaderboard/LeaderboardTable';
import { http } from '../../utils/Utils';
import PurpleButton from '../../components/UI/PurpleButton';
import { Link } from 'react-router-dom';

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [publicLeaderboardUrl, setPublicLeaderboardUrl] = useState('');
  const [count, setCount] = useState(0);
  const [skip, setSkip] = useState(0);

  const getLeaderboard = async (skipValue, limitValue) => {
    await http({
      url: '/leaderboard?skip=' + skipValue,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => {
        setLeaderboard(response.leaderboard);
        setCount(response.count);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getPublicLeaderboard = async () => {
    await http({
      url: '/leaderboard/public',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => {
        setPublicLeaderboardUrl(`/leaderboard/${response.code}`);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const generatePublicLeaderboard = async () => {
    await http({
      url: '/leaderboard/generate-public',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => {
        setPublicLeaderboardUrl(`/leaderboard/${response.code}`);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const skipFunction = (skipValue) => {
    setSkip(skipValue);
    getLeaderboard(skipValue, 10);
  };

  useEffect(() => {
    getLeaderboard(skip);
    getPublicLeaderboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      {((leaderboard && leaderboard.length > 0) || skip > 0) && (
        <>
          <div className={'pb-4'}>
            {publicLeaderboardUrl && (
              <Link
                to={publicLeaderboardUrl}
                target="_blank"
                className={`btn-sm border rounded-md text-white bg-purple-600 hover:bg-purple-700 transition duration-150`}
              >
                <div className="flex flex-grow">
                  <span className="">{'See Public Leaderboard'}</span>
                </div>
              </Link>
            )}
            {!publicLeaderboardUrl && (
              <PurpleButton
                text={'Make Leaderboard Public'}
                buttonEnabled={true}
                onClick={generatePublicLeaderboard}
              />
            )}
          </div>
          <LeaderboardTable elements={leaderboard} skipFunction={skipFunction} count={count} />
        </>
      )}
      {(!leaderboard || leaderboard.length === 0) && skip === 0 && (
        <div className="bg-white shadow-lg overflow-auto rounded-sm border border-gray-200 relative text-gray-800">
          <p className="pt-4 p-3">
            You have no Customers registered yet. <br />
            Wait to have customers before being able to see your Leaderboard.
          </p>
        </div>
      )}
    </Page>
  );
};

export default Leaderboard;
