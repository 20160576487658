import React, { useEffect } from 'react';
import { http } from '../../utils/Utils';
import Page from '../../components/Page';

const Badge = () => {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <div className="mt-6">
        <div className="flex justify-between">
          <h1 className="text-3xl font-bold">Badge</h1>
        </div>
        <div className="max-w-sm mt-6">
          <form>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <label
                  className="block text-gray-300 text-sm font-medium mb-1"
                  htmlFor="badge-name"
                >
                  Name <span className="text-red-600">*</span>
                </label>
                <input
                  id="badge-name"
                  type="text"
                  className="form-input w-full text-gray-300"
                  placeholder="Badge name"
                  required={true}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="points">
                  Points <span className="text-red-600">*</span>
                </label>
                <input
                  id="points"
                  type="text"
                  className="form-input w-full text-gray-300"
                  placeholder="Badge points"
                  required={true}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="icon">
                  Icon <span className="text-red-600">*</span>
                </label>
                <input
                  id="icon"
                  type="text"
                  className="form-input w-full text-gray-300"
                  placeholder="Icon URL"
                  required={true}
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mt-6">
              <div className="w-full px-3">
                <button className="btn text-white bg-purple-600 hover:bg-purple-700 w-full">
                  Create Badge
                </button>
              </div>
            </div>
          </form>
          {/* <div className="mt-4">{createCustomerMessage}</div> */}
          {/* <div className="mt-4 text-red-600">{errorMessage}</div> */}
        </div>
      </div>
    </Page>
  );
};

export default Badge;
