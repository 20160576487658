import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';

import { http } from '../../utils/Utils';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import AddPointsCustomer from '../../components/Customers/AddPointsCustomer';
import EventsTable from '../../components/Events/EventsTable';
import PurpleButton from '../../components/UI/PurpleButton';
import GithubLikeCalendar from '../../components/CustomerDetails/GithubLikeCalendar';

const CustomerDetails = () => {
  const [customer, setCustomer] = useState({});
  const [createdAt, setCreatedAt] = useState(new Date());
  const [elements, setElements] = useState([]);
  const [points, setPoints] = useState(1);
  const [pointsHistory, setPointsHistory] = useState([]);
  const [skip, setSkip] = useState(0);

  const params = useParams();

  const fetchCustomer = async () => {
    await http({
      url: '/customers/' + params.id,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => {
        setCustomer(response);
        setCreatedAt(new Date(response.createdAt));
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const fetchEvents = async () => {
    await http({
      url: '/events/' + params.id,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => {
        setElements(response);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const fetchPointsHistory = async () => {
    await http({
      url: '/points/' + params.id,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => {
        const values = convertPoints(response.points);

        setPointsHistory(values);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const updatePoints = async () => {
    setCustomer({ ...customer, points: points });
  };

  const convertPoints = (pointsHistory) => {
    const points = {};
    pointsHistory.forEach((point) => {
      points[point.day] = point.points;
    });
    return points;
  };

  const skipFunction = (skipValue) => {
    setSkip(skipValue);
    fetchEvents(skipValue, 20);
  };

  useEffect(() => {
    fetchCustomer();
    fetchEvents();
    fetchPointsHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer.points]);

  function handleChange(event) {
    setPoints(event.target.value);
  }

  return (
    <Page>
      <div className={'mt-10'}>
        {' '}
        <div className="flex p-6 bg-white text-black rounded-lg border border-gray-200 shadow-md">
          <div className="flex-1">
            <p className="font-normal">
              <img className="h-20" alt={customer.avatar} src={customer?.avatar} />
            </p>

            <p className="mt-4 text-lg">
              FirstName: <strong>{customer.firstName}</strong>
            </p>
            <p className="mt-1 text-lg">
              LastName: <strong>{customer.lastName}</strong>
            </p>
            <p className="mt-1 text-lg">
              <strong>{customer.points}</strong> points
            </p>
            <p className="mt-1 text-lg">{format(createdAt, 'MMMM dd yyyy HH:mm:ss')}</p>
            {pointsHistory && (
              <div className="mt-4 text-lg">
                <GithubLikeCalendar pointsHistory={pointsHistory}> </GithubLikeCalendar>
              </div>
            )}
          </div>
          <div className="flex-2 ml-auto">
            <div className="flex flex-row">
              <div className="pr-2">
                <AddPointsCustomer
                  customerId={params.id}
                  points={points}
                  updatePoints={updatePoints}
                />
              </div>
              <div>
                <input
                  id="points"
                  type="number"
                  className="w-20 h-10 form-input text-gray-800"
                  value={points}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="flex-row">
              <input type="number" />
            </div>
            <div>
              <PurpleButton text={'Update'} />
            </div>
          </div>
        </div>
        <div className="block p-6 mt-4 bg-white text-black rounded-lg border border-gray-200 shadow-md">
          <div className="text-lg">Badges</div>
          <div className="mt-2 flex flex-row">
            {customer?.badges?.map((badge) => {
              return (
                <img src={badge.icon} height="40" width="40" alt={badge.name} key={badge._id}></img>
              );
            })}
          </div>
        </div>
        <div className="block p-6 mt-4 bg-white rounded-lg border border-gray-200 shadow-md">
          {
            elements && elements.events && elements.events.length > 0 && (
              <EventsTable
                skip={skip}
                elements={elements.events}
                count={elements.count}
                skipFunction={skipFunction}
              />
            )
            // console.log(elements)
          }
        </div>
      </div>
    </Page>
  );
};

export default CustomerDetails;
