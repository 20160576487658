import React from 'react'

export default function ClientIcon({ selected }) {
	return (
		<svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
			<path
				className={`fill-current text-gray-600 ${selected && 'text-indigo-500'}`}
				d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
			/>
			<path
				className={`fill-current text-gray-400 ${selected && 'text-indigo-300'}`}
				d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
			/>
		</svg>
	)
}
