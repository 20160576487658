import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
import { http } from '../../utils/Utils';
import EventsTable from '../../components/Events/EventsTable';

const Dashboard = () => {
  const [stats, setStats] = useState({});
  const [elements, setElements] = useState([]);
  const confirmed = localStorage.getItem('confirmed');
  const [skip, setSkip] = useState(0);

  const fetchStats = async () => {
    await http({
      url: '/stats',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => {
        setStats(response);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const fetchEvents = async (skipValue, limitValue) => {
    await http({
      url: '/events?skip=' + skipValue,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => {
        setElements(response);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const skipFunction = (skipValue) => {
    setSkip(skipValue);
    fetchEvents(skipValue, 20);
  };

  useEffect(() => {
    fetchStats();
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      {!confirmed && (
        <div className="">
          <div
            className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4"
            role="alert"
          >
            <p className="font-bold">Account not verified.</p>
            <p>We sent you an email, please confirm your account by clicking the link.</p>
          </div>
        </div>
      )}
      <div className="">
        <div className="flex">
          <div className="flex-3 pr-5">
            <Link
              to="/dashboard/customers"
              className="block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                Total customers
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">{stats.customersCount}</p>
            </Link>
          </div>
          <div className="flex-3 pr-5">
            <Link
              to="/dashboard/badges"
              className="block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                Total badges
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">{stats.badgesCount}</p>
            </Link>
          </div>
          <div className="flex-3 pr-5">
            <Link
              to="/dashboard/events"
              className="block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                Total events
              </h5>
              <p className="font-normal text-gray-700 dark:text-gray-400">{stats.eventsCount}</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="">
        <div className={'mt-5'}>
          {elements && elements.events && elements.events.length > 0 && (
            <EventsTable
              skip={skip}
              elements={elements.events}
              count={elements.count}
              skipFunction={skipFunction}
            />
          )}
        </div>
      </div>
    </Page>
  );
};

export default Dashboard;
