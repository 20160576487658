import React from 'react';

function Process() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-10 md:pt-16">
          {/* Section header */}
          {/* <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4" data-aos="fade-up">
              Add compelling-ness to your product by simply integrating an API
            </h2>
            <p className="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200">
              You don’t need anymore to work on leaderboards, badges, and rankings. Make your
              product cooler and engaging with less effort.
            </p>
          </div> */}

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none">
            {/* 1st item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up">
              <div
                aria-hidden="true"
                className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block"
                style={{ width: 'calc(100% - 32px)', left: 'calc(50% + 48px)', top: '32px' }}
                data-aos="fade-in"
                data-aos-delay="200"
              ></div>
              <svg
                className="w-16 h-16 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                <path
                  className="stroke-current text-purple-300"
                  strokeWidth="2"
                  strokeLinecap="square"
                  d="M21 23h22v18H21z"
                  fill="none"
                  fillRule="evenodd"
                />
                <path
                  className="stroke-current text-purple-100"
                  d="M26 28h12M26 32h12M26 36h5"
                  strokeWidth="2"
                  strokeLinecap="square"
                />
              </svg>
              <h4 className="h4 mb-2">
                <span className="text-gray-400">1</span>. Track
              </h4>
              <p className="text-lg text-gray-400 text-center">Track your website interactions</p>
            </div>

            {/* 2nd item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div
                aria-hidden="true"
                className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block"
                style={{ width: 'calc(100% - 32px)', left: 'calc(50% + 48px)', top: '32px' }}
                data-aos="fade-in"
                data-aos-delay="400"
              ></div>
              <svg
                className="w-16 h-16 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                <g fill="none" fillRule="evenodd">
                  <path
                    className="stroke-current text-purple-300"
                    d="M40 22a2 2 0 012 2v16a2 2 0 01-2 2H24a2 2 0 01-2-2V24a2 2 0 012-2"
                    strokeWidth="2"
                    strokeLinecap="square"
                  />
                  <path
                    className="stroke-current text-purple-100"
                    strokeWidth="2"
                    strokeLinecap="square"
                    d="M36 32l-4-3-4 3V22h8z"
                  />
                </g>
              </svg>
              <h4 className="h4 mb-2">
                <span className="text-gray-400">2</span>. Identify
              </h4>
              <p className="text-lg text-gray-400 text-center">
                Identify promising users and conversion triggers
              </p>
            </div>

            {/* 3rd item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <svg
                className="w-16 h-16 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                <path
                  className="stroke-current text-purple-300"
                  strokeWidth="2"
                  strokeLinecap="square"
                  d="M21 35l4 4 12-15"
                  fill="none"
                  fillRule="evenodd"
                />
                <path
                  className="stroke-current text-purple-100"
                  d="M42 29h-3M42 34h-7M42 39H31"
                  strokeWidth="2"
                  strokeLinecap="square"
                />
              </svg>
              <h4 className="h4 mb-2">
                <span className="text-gray-400">3</span>. Engage
              </h4>
              <p className="text-lg text-gray-400 text-center">
                Engage your users with our automated email workflows
              </p>
            </div>
          </div>

          {/* Section header */}
          <div className="mt-20 max-w-3xl mx-auto pb-12 md:pb-20">
            <p className="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200">
              Our AI-powered messaging combines performance-driven workflows with company content
              and customer behavior data to deliver timely personalized messages, ultimately
              improving customer lifetime value. We approach engagement from an engineering
              perspective, offering an AI-based automated solution rather than simply providing
              tools that need to be customized and managed.
            </p>

            <h4 className="h4 mb-2 mt-10">Easy Integration</h4>
            <p className="text-xl text-gray-400 mt-2" data-aos="fade-up" data-aos-delay="200">
              We collect and use customers' data by simplifing the implementation process for our
              users.
            </p>
            <h4 className="h4 mb-2 mt-10">Personalization</h4>
            <p className="text-xl text-gray-400 mt-2" data-aos="fade-up" data-aos-delay="200">
              Our platform uses AI to personalize customers' communications based on company
              distinctive features and customers' behavior.
            </p>
            <h4 className="h4 mb-2 mt-10">Custom Dashboard</h4>
            <p className="text-xl text-gray-400 mt-2" data-aos="fade-up" data-aos-delay="200">
              We provide a custom dashboard for our users, giving them deep insight into user
              activity and engagement data.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Process;
