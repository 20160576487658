import React, { useState, useEffect } from 'react';
import { focusHandling } from 'cruip-js-toolkit';
import LeaderboardTableItem from './LeaderboardTableItem';
import PaginationClassic from '../UI/PaginationClassic';

const LeaderboardTable = ({ elements, count, skipFunction }) => {
  const [list, setList] = useState([]);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    setList(elements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements]);

  useEffect(() => {
    focusHandling('outline');
  }, [list]);

  const updateSkipHandler = (skipValue) => {
    skipFunction(skipValue);
    setSkip(skipValue);
  };

  return (
    <div className="bg-white shadow-lg overflow-auto rounded-lg border border-gray-200 max-w-xl relative">
      <div>
        {/* Table */}
        <div className="overflow-x-auto px-5">
          <table className="table-auto w-full " data-aos="fade-up" data-aos-delay="100">
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-200">
              {elements?.slice().map((customer, index) => {
                return (
                  <LeaderboardTableItem key={index} customer={customer} index={index + skip} />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="md:flex md:justify-center m-6">
        <PaginationClassic skip={skip} count={count} updateSkipHandler={updateSkipHandler} />
      </div>
    </div>
  );
};

export default LeaderboardTable;
