import React from 'react';
import Calendar from 'react-github-contribution-calendar';

var until = new Date();

const GithubLikeCalendar = ({ pointsHistory }) => {
  try {
    return <Calendar values={pointsHistory} until={until} />;
  } catch {
    return <div></div>;
  }
};

export default GithubLikeCalendar;
