import React, { useEffect, useRef, useState } from 'react';
import CustomersTable from '../../components/Customers/CustomersTable';
import PurpleButton from '../../components/UI/PurpleButton';
import { http } from '../../utils/Utils';
import Page from '../../components/Page';

const Customers = () => {
  const [elements, setElements] = useState([]);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showCreateCustomer, setShowCreateCustomer] = useState(false);
  const [createCustomerMessage, setCreateCustomerMessage] = useState('');
  const firstNameRef = useRef('');
  const lastNameRef = useRef('');
  const [skip, setSkip] = useState(0);

  const fetchCustomers = async (skipValue, limitValue) => {
    await http({
      url: '/customers?skip=' + skipValue,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => {
        setElements(response);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const skipFunction = (skipValue) => {
    setSkip(skipValue);
    fetchCustomers(skipValue, 20);
  };

  const createNewCustomer = async (e) => {
    e.preventDefault();
    setButtonEnabled(false);
    setErrorMessage('');
    if (firstNameRef.current.value === '' || lastNameRef.current.value === '') {
      setErrorMessage('Please fill in all fields');
      setButtonEnabled(true);
      return;
    }
    await http({
      url: '/customers',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      form: {
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
      },
    })
      .then((response) => {
        fetchCustomers();
        setButtonEnabled(true);
        setCreateCustomerMessage('Customer created successfully');
        firstNameRef.current.value = '';
        lastNameRef.current.value = '';
      })
      .catch((error) => {
        setButtonEnabled(true);
        setErrorMessage('Error creating customer');
      });
  };

  return (
    <Page>
      {!showCreateCustomer && (
        <PurpleButton
          onClick={(e) => setShowCreateCustomer(true)}
          text={'Create Customer'}
          buttonEnabled={buttonEnabled}
        />
      )}
      {showCreateCustomer && (
        <PurpleButton
          onClick={(e) => setShowCreateCustomer(false)}
          text={'Back'}
          buttonEnabled={buttonEnabled}
        />
      )}
      {showCreateCustomer && (
        <div className="max-w-sm mt-6">
          <form>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <label
                  className="block text-gray-300 text-sm font-medium mb-1"
                  htmlFor="first-name"
                >
                  First Name <span className="text-red-600">*</span>
                </label>
                <input
                  id="first-name"
                  type="text"
                  className="form-input w-full text-gray-300"
                  placeholder="Customer first name"
                  ref={firstNameRef}
                  required={true}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="last-name">
                  Last Name <span className="text-red-600">*</span>
                </label>
                <input
                  id="last-name"
                  type="text"
                  className="form-input w-full text-gray-300"
                  placeholder="Customer last name"
                  ref={lastNameRef}
                  required={true}
                />
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mt-6">
              <div className="w-full px-3">
                <button
                  onClick={(e) => createNewCustomer(e)}
                  className="btn text-white bg-purple-600 hover:bg-purple-700 w-full"
                >
                  Create customer
                </button>
              </div>
            </div>
          </form>
          <div className="mt-4">{createCustomerMessage}</div>
          <div className="mt-4 text-red-600">{errorMessage}</div>
        </div>
      )}
      {!showCreateCustomer && (
        <div className="mt-4">
          {elements.customers && elements.customers.length > 0 && (
            <CustomersTable
              elements={elements.customers}
              count={elements.count}
              skip={skip}
              skipFunction={skipFunction}
            />
          )}
        </div>
      )}
      {(!elements.customers || elements.count === 0) && (
        <div className="bg-white shadow-lg overflow-auto rounded-sm border border-gray-200 relative text-gray-800">
          <p className="pt-4 p-3">
            You have no Customers registered yet. <br />
            You can create Customers via this dashboard or via API.
          </p>
        </div>
      )}
    </Page>
  );
};

export default Customers;
