import React, { useState, useEffect } from 'react';
import { focusHandling } from 'cruip-js-toolkit';
import ApiKeyTableItem from './ApiKeyTableItem';

function ApiKeysTable({ elements }) {
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(elements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements]);

  useEffect(() => {
    focusHandling('outline');
  }, [list]);

  return (
    <div className="bg-white shadow-lg overflow-auto rounded-lg border border-gray-200 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-gray-800">
          API Keys <span className="text-gray-400 font-medium">{elements.length}</span>
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto px-5">
          <table className="table-auto w-full " data-aos="fade-up" data-aos-delay="100">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
              <tr>
                <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">API Key</div>
                </th>
                <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Enabled</div>
                </th>
                <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Created</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-200">
              {elements
                ?.slice()
                .reverse()
                .map((api_key, index) => {
                  return (
                    <ApiKeyTableItem key={index} api_key={api_key} enabled={api_key.enabled} />
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ApiKeysTable;
