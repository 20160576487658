import React, { useContext, useEffect } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';
import AuthContext from './store/authContext';

import Home from './pages/Home';
import PageNotFound from './pages/PageNotFound';
import Sign from './pages/Sign';
import Register from './pages/Register';
import Confirmation from './pages/Confirmation';
import ConfirmRegistration from './pages/ConfirmRegistration';
import ResetPassword from './pages/ResetPassword';
import Dashboard from './pages/dashboard/Dashboard';
import ApiKeys from './pages/dashboard/ApiKeys';
import Badge from './pages/dashboard/Badge';
import Badges from './pages/dashboard/Badges';
import Customers from './pages/dashboard/Customers';
import CustomerDetails from './pages/dashboard/CustomerDetails';
import Leaderboard from './pages/dashboard/Leaderboard';
import Settings from './pages/dashboard/Settings';
import PublicLeaderboard from './pages/PublicLeaderboard';

import Pricing from './pages/Pricing';

function App() {
  const location = useLocation();
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/signin">
          <Sign />
        </Route>
        <Route path="/signup">
          <Register />
        </Route>
        <Route path="/confirmation">
          <Confirmation />
        </Route>
        <Route path="/confirmregistration/:id">
          <ConfirmRegistration />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/pricing">
          <Pricing />
        </Route>
        <Route path="/leaderboard">
          <PublicLeaderboard />
        </Route>
        {authCtx.isLoggedIn && (
          <Route path="/dashboard/api-keys">
            <ApiKeys />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/dashboard/badges">
            <Badges />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/dashboard/badge">
            <Badge />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/dashboard/customers/:id">
            <CustomerDetails />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/dashboard/customers">
            <Customers />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/dashboard/leaderboard">
            <Leaderboard />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/dashboard/settings">
            <Settings />
          </Route>
        )}
        <Route path="/dashboard">
          {authCtx.isLoggedIn && <Dashboard />}
          {!authCtx.isLoggedIn && <Redirect to="/" />}
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
}

export default App;
