import React, { useState, useEffect } from 'react';
import { focusHandling } from 'cruip-js-toolkit';
import EventTableItem from './EventTableItem';
import PaginationClassic from '../UI/PaginationClassic';

function EventsTable({ elements, count, skipFunction }) {
  const [list, setList] = useState([]);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    setList(elements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements]);

  useEffect(() => {
    focusHandling('outline');
  }, [list]);

  const updateSkipHandler = (skipValue) => {
    skipFunction(skipValue);
    setSkip(skipValue);
  };

  return (
    <div className="bg-white shadow-lg overflow-auto rounded-lg border border-gray-200 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-gray-800">
          Events <span className="text-gray-400 font-medium">{count}</span>
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto px-5">
          <table className="table-auto w-full " data-aos="fade-up" data-aos-delay="100">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
              <tr>
                <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Description</div>
                </th>
                <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Created</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-200">
              {elements?.slice().map((event, index) => {
                return <EventTableItem key={index} event={event} />;
              })}
            </tbody>
          </table>
        </div>
        <div className="md:flex md:justify-center m-6">
          <PaginationClassic
            skip={skip}
            count={count}
            updateSkipHandler={updateSkipHandler}
            pageSize={20}
          />
        </div>
      </div>
    </div>
  );
}

export default EventsTable;
