import React from 'react';
import { format } from 'date-fns';

const EventTableItem = (props) => {
  return (
    <tr>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{props?.event?.name}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{props?.event?.description}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">
          {format(new Date(props?.event?.createdAt), 'MMMM dd yyyy HH:mm:ss')}
        </p>
      </td>
    </tr>
  );
};

export default EventTableItem;
