import React, { useContext, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { http } from '../utils/Utils';
import AuthContext from '../store/authContext';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';

const Signin = () => {
  const emailRef = useRef('');
  const passwordRef = useRef('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const DELTA_TIME = 3000000 * 1000;

  const signInCustomer = (e) => {
    e.preventDefault();
    setErrorMessage('');

    http({
      url: '/users/login',
      method: 'POST',

      form: {
        email: emailRef.current.value,
        password: passwordRef.current.value,
      },
    })
      .then((json) => {
        const expirationTime = new Date(new Date().getTime() + DELTA_TIME);
        authCtx.login(json?.token, expirationTime.toISOString(), json?.user);
        history.push('/dashboard');
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err?.reason?.error || 'Error signing in user');
      });
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">Sign In</h1>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <form onSubmit={signInCustomer}>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block text-gray-300 text-sm font-medium mb-1"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        id="email"
                        type="email"
                        className="form-input w-full text-gray-300"
                        placeholder="you@yourcompany.com"
                        autoComplete="on"
                        ref={emailRef}
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block text-gray-300 text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <input
                        id="password"
                        type="password"
                        className="form-input w-full text-gray-300"
                        placeholder="Password"
                        autoComplete="on"
                        ref={passwordRef}
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <div className="flex justify-between">
                        {/* <label className="flex items-center">
                          <input type="checkbox" className="form-checkbox" />
                          <span className="text-gray-400 ml-2">Keep me signed in</span>
                        </label> */}
                        <Link
                          to="/reset-password"
                          className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out"
                        >
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button className="btn text-white bg-purple-600 hover:bg-purple-700 w-full">
                        Sign in
                      </button>
                    </div>
                  </div>
                </form>
                <div className="mt-4 text-red-600">{errorMessage}</div>

                <div className="text-gray-400 text-center mt-6">
                  Don't you have an account?{' '}
                  <Link
                    to="/signup"
                    className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out"
                  >
                    Sign up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Signin;
