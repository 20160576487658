import React from 'react';

const PurpleButton = ({ children, ...props }) => {
  return (
    <button
      className={`btn btn-sm text-white rounded-md border bg-purple-600 hover:bg-purple-700 w-40 mb-4 sm:mb-0 ${props.buttonEnabled}`}
      onClick={(e) => props.onClick(e)}
    >
      {props.text}
    </button>
  );
};

export default PurpleButton;
