import React, { useEffect, useState } from 'react';
import ApiKeysTable from '../../components/APIKeys/ApiKeysTable';
import PurpleButton from '../../components/UI/PurpleButton';
import { http } from '../../utils/Utils';
import Page from '../../components/Page';

const ApiKeys = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchApiKeys = async (skipValue, limitValue) => {
    await http({
      url: '/api-keys',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => {
        setApiKeys(response);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    fetchApiKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createNewApiKey = async (e) => {
    e.preventDefault();
    setButtonEnabled(false);
    setErrorMessage('');
    await http({
      url: '/api-keys',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => {
        fetchApiKeys();
        setButtonEnabled(true);
      })
      .catch((error) => {
        setButtonEnabled(true);
        setErrorMessage('Error creating API key');
      });
  };

  return (
    <Page>
      <PurpleButton
        onClick={(e) => createNewApiKey(e)}
        text={'Create API Key'}
        buttonEnabled={buttonEnabled}
      />
      <div className="mt-4">{errorMessage}</div>
      <div className="mt-2">
        {apiKeys && apiKeys.length > 0 && <ApiKeysTable elements={apiKeys} />}
      </div>
      {(!apiKeys || apiKeys.length === 0) && (
        <div className="bg-white shadow-lg overflow-auto rounded-sm border border-gray-200 relative text-gray-800">
          <p className="pt-4 p-3">
            You have no API keys yet. <br />
            You need one to integrate Engammo to your application.
          </p>
        </div>
      )}
    </Page>
  );
};

export default ApiKeys;
