import React, { useState } from 'react';
import { format } from 'date-fns';
import { http } from '../../utils/Utils';

const ApiKeyTableItem = (props) => {
  const [, setEnabled] = useState(props.api_key.enabled);

  const disableApiKey = async (event, apiKeyId) => {
    event.preventDefault();
    await http({
      url: `/api-keys/${apiKeyId}/disable`,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => {
        setEnabled(response.enabled);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <tr>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{props.api_key?.apiKey}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">
          {props.api_key?.enabled ? 'true' : 'false'}
        </p>
      </td>
      {/* <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{enabled ? 'true' : 'false'}</p>
      </td> */}
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">
          {format(new Date(props.api_key?.createdAt), 'MMMM dd yyyy HH:mm:ss')}
        </p>
      </td>
      <td>
        {props.api_key?.enabled ? (
          <button
            className={`btn btn-sm text-gray-600 bg-white border-purple-600 hover:bg-purple-700 hover:text-white w-full mb-4 sm:w-auto sm:mb-0 }`}
            onClick={(event) => disableApiKey(event, props.api_key._id)}
          >
            Disable
          </button>
        ) : (
          ''
        )}
      </td>
    </tr>
  );
};

export default ApiKeyTableItem;
