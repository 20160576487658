import React from 'react';
import Header from '../partials/Header';
import Sidebar from './Dashboard/Sidebar';

const Page = ({ children, canAccess, loading }) => {
  return (
    <div className="flex flex-col min-h-screen">
      {/*  Site header */}
      <div className="flex">
        <div className="w-full h-16">
          <Header location={'dashboard'} />
        </div>
      </div>
      <div className="flex">
        <aside className="h-screen sticky top-0">
          <div className="w-60">
            <Sidebar />
          </div>
        </aside>

        {/*  Page content */}
        <main className="flex-grow">
          <div className="w-full mt-6">
            <div className="p-4">
              {loading ? (
                <div className="p-5 text-center">Loading...</div>
              ) : (
                <div className="p-2 h-full overflow-auto flex flex-col">{children}</div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Page;
