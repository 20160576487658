import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import BarLoader from 'react-spinners/BarLoader';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function ConfirmRegistration() {
  let [loading] = useState(true);
  let [color] = useState('#ffffff');
  const [showResults, setShowResults] = React.useState(false);
  const [showResultsNotOk, setShowResultsNotOk] = React.useState(false);

  const User = () => {
    let { id } = useParams();
    const result = id;
    return result;
  };

  fetch('http://localhost:3002/v1/customers/confirmregistration/' + User(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.status === 'OK') {
        setShowResults(true);
      } else {
        setShowResultsNotOk(true);
      }
    })
    .catch((error) => {
      console.log('error');
      console.log(error);
    });

  const Results = () => (
    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
      <p className="text-xl text-gray-400">Thank you for having registered to Engammo.</p>
      <p className="text-xl text-gray-400">
        Go to the{' '}
        <b>
          <Link to="/signin" className="text-purple-600 hover:text-gray-200">
            Sign in
          </Link>
        </b>{' '}
        page to access your account.
      </p>
    </div>
  );

  const ResultsNotOk = () => (
    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
      <p className="text-xl text-gray-400">User not confirmed.</p>
      <p className="text-xl text-gray-400">Please retry or contact hello@justgamification.com</p>
    </div>
  );

  const Loading = () => (
    <div>
      <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
        <BarLoader color={color} loading={loading} css={override} size={150} />
      </div>
      <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
        <p className="text-xl text-gray-400">We are confirming your account...</p>
        <p className="text-xl text-gray-400">Please wait a few seconds.</p>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">Confirm Registration</h1>
              </div>

              {/* Section header */}
              {(() => {
                if (showResults === true) {
                  return <Results />;
                } else if (showResultsNotOk === true) {
                  return <ResultsNotOk />;
                } else {
                  return <Loading />;
                }
              })()}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default ConfirmRegistration;
