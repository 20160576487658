import React, { useEffect, useState } from 'react';
import PublicLeaderboardTable from '../components/Leaderboard/PublicLeaderboardTable';
import { http } from '../utils/Utils';

const PublicLeaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [count, setCount] = useState(0);
  const [skip, setSkip] = useState(0);

  const getLeaderboard = async (skipValue) => {
    const thePath = window.location.pathname;
    const lastItem = thePath.substring(thePath.lastIndexOf('/') + 1);

    await http({
      url: `/leaderboard/public/${lastItem}?skip=${skipValue}`,
      method: 'GET',
    })
      .then((response) => {
        setLeaderboard(response.leaderboard);
        setCount(response.count);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const skipFunction = (skipValue) => {
    setSkip(skipValue);
    getLeaderboard(skipValue, 10);
  };

  useEffect(() => {
    getLeaderboard(skip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}

      {/*  Page content */}
      <main className="flex-grow">
        <div className="flex h-full">
          <div className="m-auto">
            {((leaderboard && leaderboard.length > 0) || skip > 0) && (
              <div className={'mt-10'}>
                <>
                  <PublicLeaderboardTable
                    elements={leaderboard}
                    skipFunction={skipFunction}
                    count={count}
                  />
                </>
              </div>
            )}
          </div>
        </div>

        {/* <div className="md:flex mb-4">
          <div className="sm:w-full m-auto">
            <div className="p-4">
              {((leaderboard && leaderboard.length > 0) || skip > 0) && (
                <div className={'mt-10'}>
                  <>
                    <PublicLeaderboardTable
                      elements={leaderboard}
                      skipFunction={skipFunction}
                      count={count}
                    />
                  </>
                </div>
              )}
            </div>
          </div>
        </div> */}
      </main>
    </div>
  );
};

export default PublicLeaderboard;
