import React from 'react';

import ClientIcon from '../../assets/icons/ClientIcon';
import TransactionsIcon from '../../assets/icons/TransactionsIcon';
import DashboardIcon from '../../assets/icons/DashboardIcon';
import SettingsIcon from '../../assets/icons/SettingsIcon';
import BadgeIcon from '../../assets/icons/BadgeIcon';
import LeaderboardIcon from '../../assets/icons/LeaderboardIcon';

export const SIDEBAR_MENU = {
  '/dashboard': {
    label: 'Dashboard',
    icon: (selected) => <DashboardIcon selected={selected} />,
  },
  // eslint-disable-next-line no-dupe-keys
  '/dashboard/api-keys': {
    label: 'API Keys',
    icon: (selected) => <TransactionsIcon selected={selected} />,
  },
  // eslint-disable-next-line no-dupe-keys
  '/dashboard/customers': {
    label: 'Customers',
    icon: (selected) => <ClientIcon selected={selected} />,
  },
  // eslint-disable-next-line no-dupe-keys
  '/dashboard/leaderboard': {
    label: 'Leaderboard',
    icon: (selected) => <LeaderboardIcon selected={selected} />,
  },
  // eslint-disable-next-line no-dupe-keys
  '/dashboard/badges': {
    label: 'Badges',
    icon: (selected) => <BadgeIcon selected={selected} />,
  },
  // eslint-disable-next-line no-dupe-keys
  '/dashboard/settings': {
    label: 'Settings',
    icon: (selected) => <SettingsIcon selected={selected} />,
  },
};
