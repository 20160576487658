import React from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import PricingTables from '../partials/PricingTables';

function Pricing() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/* <div className="max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PricingTables />
        </div> */}
        <div className="p-4 mx-auto">
          <PricingTables />
        </div>
      </main>
      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Pricing;
