import React, { useContext, useState, useRef, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { SIDEBAR_MENU } from '../components/Dashboard/SidebarMenu';

import AuthContext from '../store/authContext';

function Header() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const authToken = localStorage.getItem('authToken');
  const authCtx = useContext(AuthContext);

  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen || mobileNav.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const logoutUser = (e) => {
    e.preventDefault();
    authCtx.logout();
    // history.push('/dashboard');
  };

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Engammo">
              <img className="h-12 w-auto rounded-full" src="/logo192.png" alt="Engammo" />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:flex-grow">
            {/* Desktop menu links */}
            {!authToken && <ul className="flex flex-grow justify-end flex-wrap items-center"></ul>}

            {/* Desktop sign in links */}
            {!authToken && (
              <ul className="flex flex-grow justify-end flex-wrap items-center">
                <li>
                  <a
                    href="https://txha5k9p4gt.typeform.com/to/MSSFfNQm"
                    className="btn text-white bg-purple-600 hover:bg-purple-700 w-full mb-4 sm:w-auto sm:mb-0"
                  >
                    Waiting list 🚀
                  </a>
                </li>
                {/* <li>
                  <Link
                    to="/signin"
                    className="font-medium text-purple-600 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out"
                  >
                    Sign in
                  </Link>
                </li>
                <li>
                  <Link
                    to="/signup"
                    className="btn-sm text-white bg-purple-600 hover:bg-purple-700 ml-3"
                  >
                    Sign up - Beta
                  </Link>
                </li> */}
              </ul>
            )}
            {authToken && (
              <ul className="flex flex-grow justify-end flex-wrap items-center">
                <li>
                  <Link
                    to={'/dashboard'}
                    className={`btn-sm rounded-md border text-white bg-purple-600 hover:bg-purple-700 ml-3 transition duration-150`}
                  >
                    <div className="flex flex-grow">
                      <span className="">{'Go to Dashboard'}</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <button
                    onClick={logoutUser}
                    className={`btn-sm rounded-md border text-white bg-purple-600 hover:bg-purple-700 ml-3 transition duration-150`}
                  >
                    <div className="flex flex-grow">
                      <span className="">{'Logout'}</span>
                    </div>
                  </button>
                </li>
              </ul>
            )}
          </nav>

          {/* Mobile menu */}
          <div className="md:hidden">
            {/* Hamburger button */}
            <button
              className={`hamburger ${mobileNavOpen && 'active'}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <nav
              id="mobile-nav"
              ref={mobileNav}
              className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
              style={
                mobileNavOpen
                  ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 }
                  : { maxHeight: 0, opacity: 0.8 }
              }
            >
              {authToken && (
                <ul className="bg-gray-800 px-4 py-2">
                  {Object.keys(SIDEBAR_MENU).map((path, key) => {
                    const item = SIDEBAR_MENU[path];
                    return (
                      <li key={key} className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 `}>
                        <NavLink
                          exact
                          to={path}
                          className={`block text-white hover:text-white transition duration-150`}
                        >
                          <div className="flex flex-grow">
                            <span className="text-sm font-medium">{item.label}</span>
                          </div>
                        </NavLink>
                      </li>
                    );
                  })}
                  <li>
                    <button
                      onClick={logoutUser}
                      className={`btn-sm text-white bg-purple-600 hover:bg-purple-700 ml-3 transition duration-150`}
                    >
                      <div className="flex flex-grow">
                        <span className="">{'Logout'}</span>
                      </div>
                    </button>
                  </li>
                </ul>
              )}
              {!authToken && (
                <ul className="bg-gray-800 px-4 py-2">
                  <li>
                    <a
                      href="https://txha5k9p4gt.typeform.com/to/MSSFfNQm"
                      className="btn text-white bg-purple-600 hover:bg-purple-700 w-full mb-4 sm:w-auto sm:mb-0"
                    >
                      Subscribe to the waiting list
                    </a>
                  </li>
                  {/* <li>
                    <Link
                      to="/signin"
                      className="flex font-medium w-full text-purple-600 hover:text-gray-200 py-2 justify-center"
                    >
                      Sign in
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/signup"
                      className="font-medium w-full inline-flex items-center justify-center border border-transparent 
                  px-4 py-2 my-2 rounded-sm text-white bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out"
                    >
                      Sign up - Beta
                    </Link>
                  </li> */}
                </ul>
              )}
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
