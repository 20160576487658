import React from 'react';
import PurpleButton from '../UI/PurpleButton';
import { http } from '../../utils/Utils';

const AddPointsCustomer = ({ customerId, points, updatePoints }) => {
  const handleAddPoints = async (e) => {
    e.preventDefault();
    await http({
      url: '/customers/points/' + customerId,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      form: {
        points: +points,
      },
    })
      .then((response) => {
        updatePoints(response.points);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <PurpleButton onClick={(e) => handleAddPoints(e)} text={'Add Points'} buttonEnabled={true} />
  );
};

export default AddPointsCustomer;
