import React from 'react';
import { format } from 'date-fns';

const BadgeTableItem = ({ badge }) => {
  return (
    <tr>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{badge?.name}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{badge?.points}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">
          <img src={badge?.icon} width={30} alt={`${badge?.name}`} />
        </p>
      </td>

      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">
          {format(new Date(badge.createdAt), 'MMMM dd yyyy HH:mm:ss')}
        </p>
      </td>
    </tr>
  );
};

export default BadgeTableItem;
