import React, { useEffect, useState } from 'react';
import BadgesTable from '../../components/Badges/BadgesTable';
import { http } from '../../utils/Utils';
import Page from '../../components/Page';
import PurpleButton from '../../components/UI/PurpleButton';

const Badges = () => {
  const [badges, setBadges] = useState([]);
  const [buttonEnabled, setButtonEnabled] = useState(true);

  const getBadges = async (skipValue, limitValue) => {
    await http({
      url: '/badges',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => {
        setBadges(response);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  // const createNewBadge = async (e) => {
  //   e.preventDefault();
  //   setButtonEnabled(false);
  //   setErrorMessage('');
  //   if (firstNameRef.current.value === '' || lastNameRef.current.value === '') {
  //     setErrorMessage('Please fill in all fields');
  //     setButtonEnabled(true);
  //     return;
  //   }
  //   await http({
  //     url: '/customers',
  //     method: 'POST',
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('authToken')}`,
  //     },
  //     form: {
  //       firstName: firstNameRef.current.value,
  //       lastName: lastNameRef.current.value,
  //     },
  //   })
  //     .then((response) => {
  //       fetchCustomers();
  //       setButtonEnabled(true);
  //       setCreateCustomerMessage('Customer created successfully');
  //       firstNameRef.current.value = '';
  //       lastNameRef.current.value = '';
  //     })
  //     .catch((error) => {
  //       setButtonEnabled(true);
  //       setErrorMessage('Error creating customer');
  //     });
  // };

  useEffect(() => {
    getBadges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToCreateBadge = () => {
    window.location.href = '/dashboard/badge';
  };

  return (
    <Page>
      <PurpleButton
        text={'Create Badge'}
        onClick={(e) => goToCreateBadge(e)}
        buttonEnabled={buttonEnabled}
      />
      <div className="mt-6">
        {badges && badges.length > 0 && <BadgesTable elements={badges} />}
        {(!badges || badges.length === 0) && (
          <div className="bg-white shadow-lg overflow-auto rounded-sm border border-gray-200 relative text-gray-800">
            <p className="pt-4 p-3">
              You have no Badges yet. <br />
            </p>
          </div>
        )}
      </div>
    </Page>
  );
};

export default Badges;
