import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Dashboard/Sidebar';
import { http } from '../../utils/Utils';
import Header from '../../partials/Header';

const Settings = () => {
  const [user, setUser] = useState({});
  const [email, setEmail] = useState('');
  const [accountVerified, setAccountVerified] = useState(false);
  const [companyName, setCompanyName] = useState('');

  const [registrationDate, setRegistrationDate] = useState(new Date());

  const fetchSettings = async () => {
    await http({
      url: '/users',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => {
        setUser(response);
        setEmail(response.email);
        setAccountVerified(response.account_verified);
        setCompanyName(response.companyName);
        setRegistrationDate(new Date(response.createdAt).toLocaleDateString());
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const updateSettings = async (e) => {
    e.preventDefault();
    await http({
      url: '/users',
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      form: {
        companyName,
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <div className="flex">
        <div className="w-full h-16">
          <Header location={'dashboard'} />
        </div>
      </div>

      {/*  Page content */}
      <main className="flex-grow">
        <div className="flex mb-4">
          <div className="w-200">
            <Sidebar />
          </div>
          <div className="md:w-4/5 sm:w-full">
            <div className="p-4">Settings</div>
            <form className="p-2" onSubmit={updateSettings}>
              <div className="flex flex-col">
                <div className="w-64 p-2">
                  <label htmlFor="name">Email</label>
                  <input
                    className="p-2 rounded-md w-64 text-gray-800"
                    type="text"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="w-64 p-2">
                  <label htmlFor="name">Company name</label>
                  <input
                    className="p-2 rounded-md w-64 text-gray-800"
                    type="text"
                    name="email"
                    id="email"
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                  />
                </div>
                <div className="w-64 p-2">
                  <label htmlFor="name">Account Verified</label>
                  <input
                    className="p-2 rounded-md w-64 text-gray-800"
                    type="text"
                    name="email"
                    id="email"
                    disabled={true}
                    value={accountVerified}
                    onChange={(e) => {}}
                  />
                </div>
                <div className="w-64 p-2">
                  <label htmlFor="name">Registration date</label>
                  <input
                    className="p-2 rounded-md w-64 text-gray-800"
                    type="text"
                    name="email"
                    id="email"
                    value={registrationDate}
                    onChange={(e) => {}}
                  />
                </div>
                <div className="w-64 p-2">
                  <button
                    className="btn-sm text-white bg-purple-600 hover:bg-purple-700 transition duration-150"
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Settings;
