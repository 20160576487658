import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

const CustomerTableItem = ({ customer }) => {
  return (
    <tr>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <img className="h-10" alt={customer.avatar} src={customer?.avatar} />
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{customer?.firstName}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{customer?.lastName}</p>
      </td>
      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">{customer?.points}</p>
      </td>

      <td className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <p className="text-left font-semibold text-gray-600">
          {format(new Date(customer.createdAt), 'MMMM dd yyyy HH:mm:ss')}
        </p>
      </td>
      <td>
        <Link
          className={`btn btn-sm text-gray-600 bg-white border-purple-600 hover:bg-purple-700 hover:text-white w-full mb-4 sm:w-auto sm:mb-0 }`}
          to={`/dashboard/customers/${customer._id}`}
        >
          Details
        </Link>
      </td>
    </tr>
  );
};

export default CustomerTableItem;
