import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import { http } from '../utils/Utils';

const Signup = () => {
  const nameRef = useRef('');
  const emailRef = useRef('');
  const passwordRef = useRef('');
  const companyRef = useRef('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const signUpCustomer = (e) => {
    e.preventDefault();
    setErrorMessage('');

    const user = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
      name: nameRef.current.value,
      company: companyRef.current.value,
    };

    http({
      url: '/users',
      method: 'POST',

      form: {
        ...user,
      },
    })
      // .then((response) => response.json())
      .then((json) => {
        history.push('/confirmation');
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage('Error signing up user');
      });
  };

  return (
    <>
      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}
        <Header />

        {/*  Page content */}
        <main className="flex-grow">
          {/*  Page illustration */}
          <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
            <PageIllustration />
          </div>

          <section className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                {/* Page header */}
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                  <h1 className="h1">Register Now</h1>
                </div>

                {/* Form */}
                <div className="max-w-sm mx-auto">
                  <form>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label
                          className="block text-gray-300 text-sm font-medium mb-1"
                          htmlFor="full-name"
                        >
                          Full Name <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="full-name"
                          type="text"
                          className="form-input w-full text-gray-300"
                          placeholder="First and last name"
                          ref={nameRef}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label
                          className="block text-gray-300 text-sm font-medium mb-1"
                          htmlFor="company-name"
                        >
                          Company Name <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="company-name"
                          type="text"
                          className="form-input w-full text-gray-300"
                          placeholder="Your company or product name"
                          ref={companyRef}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label
                          className="block text-gray-300 text-sm font-medium mb-1"
                          htmlFor="email"
                        >
                          Work Email <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="email"
                          type="email"
                          className="form-input w-full text-gray-300"
                          placeholder="you@yourcompany.com"
                          ref={emailRef}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label
                          className="block text-gray-300 text-sm font-medium mb-1"
                          htmlFor="password"
                        >
                          Password <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="password"
                          type="password"
                          minLength={6}
                          className="form-input w-full text-gray-300"
                          placeholder="Password (at least 10 characters)"
                          autoComplete="current-password"
                          ref={passwordRef}
                          required
                        />
                      </div>
                    </div>
                    <div className="text-sm text-gray-500 text-center">
                      I agree to receive updates from Engammo, as stated in the{' '}
                      <Link
                        to="#"
                        className="underline text-gray-400 hover:text-gray-200 hover:no-underline transition duration-150 ease-in-out"
                      >
                        Privacy Policy
                      </Link>
                      .
                    </div>
                    <div className="flex flex-wrap -mx-3 mt-6">
                      <div className="w-full px-3">
                        <button
                          onClick={signUpCustomer}
                          className="btn text-white bg-purple-600 hover:bg-purple-700 w-full"
                        >
                          Sign up
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="mt-4 text-red-600">{errorMessage}</div>
                  <div className="text-gray-400 text-center mt-6">
                    Already registered to Engammo?{' '}
                    <Link
                      to="signin"
                      className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out"
                    >
                      Sign in
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>{' '}
    </>
  );
};

export default Signup;
